import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {organizationService} from "services/organization.service";
import {BusinessToOrganization, Organization, OrganizationCreate} from "types/organization";

interface InitialState {
    info: Partial<Organization> | null;
    error: string | null;
    status: 'idle' | 'loading' | 'failed';
}
const initialState: InitialState = {
    info: null,
    status: 'idle',
    error: null
}

export const getOrganization = createAsyncThunk(
    'organizations/getOrganization',
    async(organizationId: number) => {
        const response = await organizationService.getOrganization(organizationId);
        return response as Organization

    }
)

export const getOrganizations = createAsyncThunk(
    'organizations/getOrganizations',
    async (query: string) => {
        const response = await organizationService.getOrganizations(query);
        return response as Organization[];
    }
)

export const getUserOrganizations = createAsyncThunk(
    'organizations/getUserOrganizations',
    async (userId: number) => {
        const response = await organizationService.getUserOrganizations(userId);
        return response as Organization;
    }
)

export const getPredictions = createAsyncThunk(
  "organizations/getPredictions",
  async (query: string) => {
    const response = await organizationService.getPredictions(query);
    return response.data.organizations;
  }
);

export const addOrganization = createAsyncThunk(
  'organization/addOrganization',
  async (newData: OrganizationCreate) => {
    const response = await organizationService.addOrganization(newData);
    return response
  }  
);

export const removeOrganization = createAsyncThunk(
    'organization/removeOrganization',
    async (organizationId: number) => {
      const response = await organizationService.removeOrganization(organizationId);
      return response
    }  
  );

export const addBusinessToOrganization = createAsyncThunk(
    'organization/addBusinessToOrganization',
    async (newData: BusinessToOrganization) => {
        const response = await organizationService.addBusinessToOrganization(newData);
        return response
    }
);

export const removeBusinessFromOrganization = createAsyncThunk(
    'organization/removeBusinessFromOrganization',
    async(newData: BusinessToOrganization) => {
        const response = await organizationService.removeBusinessFromOrganization(newData);
        return response as Organization
    }
)

export const updateOrganization = createAsyncThunk(
    'organization/updateOrganization',
    async (newData: Partial<Organization>) => {
        const response = await organizationService.updateOrganization(newData);
        return response as Organization
    }
)




export const organizationSlice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        clearOrganization(state) {
            state.info = null;
            state.status = 'idle';
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOrganizations.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getOrganizations.fulfilled, (state, action) => {
                state.status = 'idle';
                state.error = null;
            })
            .addCase(getOrganizations.rejected, (state, action) => {
                state.status = 'failed';
                state.error = 'Failed to load account';
            })
            .addCase(getPredictions.rejected, (state, action) => {
                state.status = "failed";
                state.error = "Failed to load account";
              })
              .addCase(getPredictions.pending, (state) => {
                state.status = "loading";
                state.error = null;
              })
              .addCase(getPredictions.fulfilled, (state, action) => {
                state.status = "idle";
                state.error = null;
              })
            .addCase(getUserOrganizations.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getUserOrganizations.fulfilled, (state, action) => {
                state.status = 'idle';
                state.error = null;
            })
            .addCase(getUserOrganizations.rejected, (state, action) => {
                state.status = 'failed';
                state.error = 'Failed to load account';
            })
            .addCase(getOrganization.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getOrganization.fulfilled, (state, action) => {
                state.status = 'idle';
                state.info = action.payload;
                state.error = null;
            })
            .addCase(getOrganization.rejected, (state, action) => {
                state.status = 'failed';
                state.error = 'Failed to load account';
            })
            .addCase(updateOrganization.pending, (state) => {
                state.error = null;
            })
            .addCase(updateOrganization.fulfilled, (state, action) => {
                state.info = Object.assign({}, state.info, action.payload);
                state.error = null;
            })
            .addCase(updateOrganization.rejected, (state, action) => {
                state.status = 'failed';
                state.error = "Failed to update organization"
            })
            .addCase(addOrganization.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(addOrganization.fulfilled, (state, action) => {
                state.status = 'idle';
                state.error = null;
            })
            .addCase(removeOrganization.rejected, (state, action) => {
                state.status = 'failed';
                state.error = 'Failed to remove organization';
            })
            .addCase(removeOrganization.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(removeOrganization.fulfilled, (state, action) => {
                state.status = 'idle';
                state.error = null;
            })
            .addCase(addOrganization.rejected, (state, action) => {
                state.status = 'failed';
                state.error = 'Failed to add organization';
            })
            .addCase(addBusinessToOrganization.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(addBusinessToOrganization.fulfilled, (state, action) => {
                state.status = 'idle';
                state.info = Object.assign({}, state.info, action.payload);
                state.error = null;
            })
            .addCase(addBusinessToOrganization.rejected, (state, action) => {
                state.status = 'failed';
                state.error = 'Failed to add business to organization';
            })
            .addCase(removeBusinessFromOrganization.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(removeBusinessFromOrganization.fulfilled, (state, action) => {
                state.status = 'idle';
                state.info = Object.assign({}, state.info, action.payload);
                state.error = null;
            })
            .addCase(removeBusinessFromOrganization.rejected, (state, action) => {
                state.status = 'failed';
                state.error = 'Failed to remove business from organization';
            })
    }
})

export default organizationSlice.reducer;